import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import VideocamIcon from '@material-ui/icons/Videocam';
import InsertEmoticonIcon from '@material-ui/icons/InsertEmoticon';

import getCourses from './data.js';

import './index.css';

const colorYellow = '#F7CE3E';

const useStyles = makeStyles({
  root: {
    width: 345,
  },
  media: {
    height: 140,
  },
});

function Today() {
  const classes = useStyles();

  const now = new Date();

  const occurrence = Math.floor((now.getDate()-1) / 7) + 1;

  var courses = getCourses().filter(course =>
    course.weekDay === now.getDay() && (course.occurrence === undefined || course.occurrence === occurrence)
  );

  if (courses.length == 0) {
    return <Box p={1} style={{ margin: 'auto' }}>
      <Typography variant="h4" gutterBottom>
        Aucun cours aujourd'hui.
      </Typography>
      <Typography variant="body1" style={{verticalAlign: 'middle', display: 'inline-flex' }}>
        Et si vous en profitiez pour regarder nos replays, ou respirer loin des écrans? <InsertEmoticonIcon />
      </Typography>
    </Box>
  }

  const cards = courses.map((course) =>
    <Box display="flex" p={1}>
      <Card className={classes.root}>
        <CardActionArea href={'https://www.respire.institute/path-player?courseid=' + course.slug} target="_top">
          <CardMedia
            className={classes.media}
            image={course.image}
            title={course.name}
          />
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {course.name} <Typography variant="body1" color="textSecondary" component="span">à {course.time}</Typography>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );

  return (
    <Grid container spacing={2}>{cards}</Grid>
  );
}

function Join() {
  let { id } = useParams();

  var course = getCourses().find(course => course.id == id);

  if (course === undefined) {
    return <Box p={1} style={{ margin: 'auto' }}>
      <Typography variant="body1" >
        Ce cours n'a pas de session live.
      </Typography>
    </Box>
  }

  return (
    <Grid
      container
      spacing={0}
      align="center"
      justify="center"
      direction="column"
      style={{ backgroundColor: colorYellow, height: '100vh' }}
    >
      <Grid item>
        <Button target="_blank" variant="contained" color="primary" size="large" startIcon={<VideocamIcon />}>
          Rejoindre sur zoom
        </Button>
      </Grid>
    </Grid>
  );
}

// ========================================

ReactDOM.render(
  <Router>
    <Switch>
      <Route path="/today">
        <Today />
      </Route>
      <Route path="/join/:id">
        <Join />
      </Route>
    </Switch>
  </Router>,
  document.getElementById('root')
);
