export default function getCourses() {
    return [
        {
          name: 'Spiritualité (2ème année)',
          image: 'https://lwfiles.mycourse.app/respire-public/5a1b402897dfdfb22434a1361ed57578.jpeg',
          slug: 'spiritualite-2',
          weekDay: 1,
          time: '20h30',
        },
        {
          name: 'Riyad al Jannah',
          image: 'https://lwfiles.mycourse.app/respire-public/5c77f773589f4463df4f7d2a754ba02f.jpeg',
          slug: 'assises',
          weekDay: 1,
          time: '21h30',
        },
        {
          name: 'Spiritualité (1ère année)',
          image: 'https://lwfiles.mycourse.app/respire-public/5a1b402897dfdfb22434a1361ed57578.jpeg',
          slug: 'spiritualite',
          weekDay: 3,
          time: '20h30',
        },
        {
          name: 'Psychologie & Nutrition (2ème année)',
          image: 'https://lwfiles.mycourse.app/respire-public/839269a8101c8795922e7143c65b9f8c.jpeg',
          slug: 'psychologie-bien-etre-2',
          weekDay: 3,
          time: '20h30',
        },
        {
          name: 'Psychologie & Nutrition (1ère année)',
          image: 'https://lwfiles.mycourse.app/respire-public/839269a8101c8795922e7143c65b9f8c.jpeg',
          slug: 'psychologie-bien-etre',
          weekDay: 3,
          time: '21h30',
        },
        {
          name: 'Femmes d\'exception',
          image: 'https://lwfiles.mycourse.app/respire-public/f34ffe727641efbcbbdf5ae3fd778cbf.jpeg',
          slug: 'femmes-d-exception',
          weekDay: 5,
          time: '21h30',
        },
        {
          name: 'L\'expert du mois',
          image: 'https://lwfiles.mycourse.app/respire-public/5cc0f66d517927d747c70cd728bbf22e.jpeg',
          slug: 'expert',
          weekDay: 6,
          time: '20h30',
          occurrence: 2,
        },
        {
          name: 'Révélez votre talent',
          image: 'https://lwfiles.mycourse.app/respire-public/98b3d7085b59269a6ba9072ddde5acfe.jpeg',
          slug: 'talents',
          weekDay: 6,
          time: '21h30',
          occurrence: 2,
        },
        {
          name: 'L\'expert du mois',
          image: 'https://lwfiles.mycourse.app/respire-public/5cc0f66d517927d747c70cd728bbf22e.jpeg',
          slug: 'expert2',
          weekDay: 6,
          time: '20h30',
          occurrence: 4,
        },
        {
          name: 'Veillée nocturne',
          image: 'https://lwfiles.mycourse.app/respire-public/5c77f773589f4463df4f7d2a754ba02f.jpeg',
          slug: 'qiyam',
          weekDay: 6,
          time: '21h30',
          occurrence: 4,
        },
    ];
}
